<template v-loading="loading"
element-loading-text="拼命加载中"
element-loading-spinner="el-icon-loading"
element-loading-background="rgba(0, 0, 0, 0.8)">
    <div  >
        
        <h1>少年三国志2</h1>
        <h4>（群雄争霸大区）中秋特别活动</h4>
        <el-card class="lottery-card" >

            <div slot="header" class="clearfix">
                <i class="el-icon-present"></i>
                <span>抽奖说明</span>
            </div>
            <div class="lottery-content">
                <!-- 奖品列表 -->
                <h4>奖品列表</h4>
                <ul>
                 <!--   <li v-for="(item, index) in this.Getlist.list" :key="index">{{ item }}</li>-->
                 <li>6元游戏内等价礼包</li>
                 <li>12元游戏内等价礼包</li>   
                 <li>18元游戏内等价礼包</li>
                 <li>25元游戏内等价礼包</li>
                 <li>60元游戏内等价礼包</li>
                 <li>98元游戏内等价礼包</li>
                 <li>128元游戏内等价礼包</li>
                 <li>198元游戏内等价礼包</li>
                 <li>328元游戏内等价礼包</li>
                 <li>648元游戏内等价礼包</li>
                 <li>1298元游戏内等价礼包</li>
                 <li>1598元游戏内等价礼包</li>
                 <li>8888元游戏内等价礼包（特等奖）</li>
                </ul>

               

                <!-- 发奖方式 -->
                <h4>发奖方式</h4>
                <p>尊贵的玩家，抽中后请您第一时间截图抽中界面，然后联系我们的VIP客服进行领取</p>

            </div>
        </el-card >
            <el-form :model="Raffleinfo" ref="Raffleinfo" class="demo-ruleForm">
            <el-form-item label="玩家昵称" prop="name" :rules="[
                { required: true, message: '昵称不能为空' },
            ]">
                <el-input v-model.number="Raffleinfo.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="玩家ID" prop="ID" :rules="[
                { required: true, message: 'ID不能为空' },
            ]">
                <el-input v-model.number="Raffleinfo.ID" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="玩家区服" prop="district" :rules="[
                { required: true, message: '区服不能为空' },
            ]">
                <el-input v-model.number="Raffleinfo.district" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="努力抽奖中·····" type="primary" @click="submitForm('Raffleinfo')" >开始抽奖</el-button>
            </el-form-item>
        </el-form>
       </div>
    </template>
<script>

import axios from 'axios';


export default {
    data() {
      return {
        fullscreenLoading:false,
        jdtshow:false,
        UserName:'',
        Raffleinfo: {
            name: '',
            ID:'',
            district:''
        },
        Getlist:{
            list:[]
        }
      };
    },
    mounted() {
        console.log(this.$route.query.data);
        if (this.$route.query.data) {
            if (typeof (this.$route.query.data.captcha) === 'undefined') {
                let userString = localStorage.getItem('Raffle_captcha');
                if (userString) {
                    this.UserName = userString
                    console.log("没有传递undefined,但是有缓存");
                    console.log(this.captcha);
                    return
                } else {
                    console.log(this.captcha);
                    console.log("没有传递undefined");
                    this.ShowError('错误', '鉴权失败')
                    this.$router.push('/')
                }
            }
            this.UserName = this.$route.query.data.captcha
        } else {
            let userString = localStorage.getItem('Raffle_captcha');

            if (userString) {
                this.captcha = userString
                console.log("没有传递,但是有缓存");
                console.log(this.captcha);
            } else {
                console.log(this.captcha);
                console.log("没有传递");
                this.ShowError('错误', '鉴权失败')
                this.$router.push('/')
            }

        };
        console.log(this.UserName);
        this.Getprizelist(this.UserName)
    },
    methods: {
        Getprizelist(UserName){
            console.log(UserName);
            axios.post("/api/", JSON.stringify({
                    Type: "Getprizelist",
                    UserName: UserName
                })).then((result) => { 
                   
                    console.log(result.data);
                    this.Getlist.list=result.data
                })
        },

        ShowError(title, message) {
            this.$notify.error({
                title: title,
                message: message,
            });
        },
        ShowSuccess(title, message) {
            this.$notify({
                title: title,
                message: message,
                type: 'success'
            });
        },
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
           // alert('submit!');
           // alert("抽奖成功！！恭喜获得:代金券1000元!请将激活码告知客服查询确认")
           this.fullscreenLoading=true
           setTimeout(()=>{
            this.submitInfo(this.Raffleinfo)
           },3000)
           
        } else {
            this.ShowError('错误', '请检查信息是否填写完整')
            return false;
          }
        });
      },
      submitInfo(SRaffleinfo){
        let name = SRaffleinfo.name
        let ID=SRaffleinfo.ID
        let district= SRaffleinfo.district
     

        axios.post("/api/", JSON.stringify({
                    Type: "SubmitInfo",
                    UserName: this.UserName,
                    Name:name,
                    ID:name,
                    District:district,
                })).then((result) => { 
                    console.log(result.data);
                    if(result.data.msg === '抽奖成功'){
                        alert("抽奖成功！！恭喜获得:"+result.data.Raffle+"请将校验码告知客服查询确认")
                    this.fullscreenLoading=false
                    } else {
                       // alert(result.data.msg)
                        this.ShowError('错误', result.data.msg)
                    this.fullscreenLoading=false
                    }
                })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>
<style scoped>

.lottery-card {
    /* 自定义Card样式 */
    margin: 20px;
    border-radius: 8px;
    text-align: left;
}

.lottery-content h3 {
    margin-top: 20px;
    color: #333;
}

.lottery-content ul {
    list-style-type: disc;
    padding-left: 20px;
}

.lottery-content p {
    line-height: 1.6;
    color: #666;
}
</style>